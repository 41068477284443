import React, {useEffect,} from 'react';

interface UseClickOutsideProps {
    ref: React.RefObject<HTMLDivElement>;
    onClickOutside: () => void;
}

export const useClickOutside = (
    {
        ref, onClickOutside
    }: UseClickOutsideProps
) => {
    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            onClickOutside();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
};
