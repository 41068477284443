import React from 'react';
import {IconProps} from './types';

export const Calendar = ({color}: IconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.5 1C9.05228 1 9.5 1.44772 9.5 2V3H14.5V2C14.5 1.44772 14.9477 1 15.5 1C16.0523 1 16.5 1.44772 16.5 2V3H22C22.5523 3 23 3.44772 23 4V22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22V4C1 3.44772 1.44772 3 2 3H7.5V2C7.5 1.44772 7.94772 1 8.5 1ZM7.5 5H3V21H21V5H16.5V6C16.5 6.55228 16.0523 7 15.5 7C14.9477 7 14.5 6.55228 14.5 6V5H9.5V6C9.5 6.55228 9.05228 7 8.5 7C7.94772 7 7.5 6.55228 7.5 6V5ZM5 10C5 9.44772 5.44772 9 6 9H10C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18H6C5.44772 18 5 17.5523 5 17C5 16.4477 5.44772 16 6 16H9V14.5H7C6.44772 14.5 6 14.0523 6 13.5C6 12.9477 6.44772 12.5 7 12.5H9V11H6C5.44772 11 5 10.5523 5 10ZM13 10C13 9.44772 13.4477 9 14 9H18C18.5523 9 19 9.44772 19 10V17C19 17.5523 18.5523 18 18 18H14C13.4477 18 13 17.5523 13 17V10ZM15 11V16H17V11H15Z"
                  fill="black" fillOpacity="0.85"/>
            <path d="M15 16V11H17V16H15Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3 5H7.5V6C7.5 6.55228 7.94772 7 8.5 7C9.05228 7 9.5 6.55228 9.5 6V5H14.5V6C14.5 6.55228 14.9477 7 15.5 7C16.0523 7 16.5 6.55228 16.5 6V5H21V21H3V5ZM5 10C5 9.44772 5.44772 9 6 9H10C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18H6C5.44772 18 5 17.5523 5 17C5 16.4477 5.44772 16 6 16H9V14.5H7C6.44772 14.5 6 14.0523 6 13.5C6 12.9477 6.44772 12.5 7 12.5H9V11H6C5.44772 11 5 10.5523 5 10ZM13 10C13 9.44772 13.4477 9 14 9H18C18.5523 9 19 9.44772 19 10V17C19 17.5523 18.5523 18 18 18H14C13.4477 18 13 17.5523 13 17V10Z"
                  fill={color}/>
        </svg>

    );
};
