import React, {useEffect, useState} from 'react';
import {useQuill} from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {setIntermediateText} from '../../pages/diary/store/diary.slice';
import 'quill/dist/quill.bubble.css';
import styles from './style.module.scss';
import dayjs from 'dayjs';
import {CustomToolbar} from './CustomToolbar';
import {Close} from '../../assets/icons';
import {colors} from '../../utils/constants';


const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list',
    'link', 'image',
    'align',
    'color', 'background',
];


interface EditorProps {
    handleSave: () => void;
    onCansel: () => void;
}

export const Editor = ({handleSave, onCansel}: EditorProps) => {
    const [selectionIndex, setSelectionIndex] = useState<number>(0);
    const {quill, quillRef} = useQuill({
        modules: {toolbar: '#toolbar'},
        formats
    });
    const dispatch = useAppDispatch();
    const {intermediateText, currentDate} = useAppSelector(state => state.diary);

    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                if (source === 'user') {
                    dispatch(setIntermediateText(
                        quill?.getSemanticHTML() !== '<p></p>'
                            ? {
                                _id: intermediateText?._id,
                                text: quill?.getSemanticHTML(),
                                type: 'text',
                                date_create: currentDate,
                                time: dayjs().format('HH:mm'),
                            } : null));
                    handleSelectionChange();
                }
            });

            quill.on('selection-change', handleSelectionChange);

            if (intermediateText !== null) {
                quill.clipboard.dangerouslyPasteHTML(intermediateText.text);
            }
        }

        return () => {
            if (quill) {
                quill.off('selection-change', handleSelectionChange);
            }
        };
    }, [quill]);
    const handleSelectionChange = () => {
        if (quill) {
            const range = quill.getSelection();
            if (range) {
                setSelectionIndex(range.index as number);
            }
        }
    };
    const addEmoji = (emoji: any) => {
        if (quill) {
            setTimeout(() => {
                quill.insertText(selectionIndex, emoji.native);
                quill.setSelection(selectionIndex + emoji.native.length);
                dispatch(setIntermediateText({
                    _id: intermediateText?._id,
                    text: quill?.getSemanticHTML(),
                    type: 'text',
                    date_create: currentDate,
                    time: dayjs().format('HH:mm'),
                }));
            }, 10);
        }
    };

    return (

        <div className={styles.editor}>
            <div className={styles.background}/>

            <div className={styles.editorInner}>
                 <span className={styles.editorClose} onClick={onCansel}>
                    <Close color={colors.gold}/>
                </span>
                <CustomToolbar addEmoji={addEmoji} onSave={() => {
                    handleSave();
                    dispatch(setIntermediateText(null));
                }}/>
                <div ref={quillRef}/>
            </div>
        </div>
    );
};
