import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import {Close, Send} from '../../assets/icons';
import {colors} from '../../utils/constants';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {useSocket} from '../../hooks';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {useGetDiaryQuery, useSetPostMutation, useUpdaterPostMutation} from '../../pages/diary/store/diary.api';
import {setFirstHtmlText, setIntermediateText} from '../../pages/diary/store/diary.slice';
import {socket} from '../../App';
import {toast} from 'react-toastify';

export interface Comment {
    _id?: string;
    authorName?: string;
    authorId?: string;
    type: 'text' | 'comment',
    text: string,
    date_create: string;
    time: string;
}

export interface Message {
    post: Comment,
    position: number;
    commentsListId?: string;
    accessIds: string[];
    postContentId: string;
}

export interface CommentsProps {
    position: number;
    onHide: () => void;
}

export const Comments = (
    {position, onHide}: CommentsProps
) => {
    const dispatch = useAppDispatch();

    const {firstHtmlText, intermediateText, currentDate, dataDiary} = useAppSelector(state => state.diary);

    const {refetch} = useGetDiaryQuery({
        date_create: currentDate,
        authorId: dataDiary.studentId
    });
    const [setPost] = useSetPostMutation();
    const [updatePost] = useUpdaterPostMutation();

    const {sendMessage, message} = useSocket();

    const [list, setList] = useState<Message[]>([]);
    const [comment, setComment] = useState<Message | null>(null);

    useEffect(() => {

        if (message) {
            setList([...list,
                {
                    post: message as unknown as Comment,
                    position,
                    accessIds: dataDiary.accessIds,
                    postContentId: dataDiary.postContentId
                }
            ]);
            setTimeout(() => refetch(), 500);
        }
    }, [message]);

    const writeComment = (text: string) => {
        const item: Comment = {
            text,
            authorId: dataDiary.serviceId,
            authorName: dataDiary.authorName,
            date_create: dayjs().format('YYYY-MM-DD'),
            time: dayjs().format('HH:mm'),
            type: 'comment'
        };
        setComment({post: item, position, accessIds: dataDiary.accessIds, postContentId: dataDiary.postContentId});
    };

    const handleSave = () => {
        if (!socket.connected) {
            return toast.error('Проверьте интернет соединение, или обратитесь в поддержку');
        }
        updatePost({post: firstHtmlText as Comment})
            .unwrap()
            .then(async () => {
                await setPost({body: {post: intermediateText as Comment, position, authorId: dataDiary.studentId}});
            })
            .catch()
            .finally(() => {
                dispatch(setIntermediateText(null));
                dispatch(setFirstHtmlText(null));
                sendMessage(comment as Message);
            });

        setComment(null);
    };

    const back = () => {
        onHide();
    };

    return (
        <div className={styles.commentsWrapper}>
            <div className={styles.list}>
                {list.map((item, index) => (
                    <div key={index} className={styles.listItem}>
                        <span className={styles.listItemText}>{item.post.text}</span>
                        <div className={styles.listItemFooter}>
                            <span className={styles.listItemDate}>{item.post.date_create}</span>
                            <span className={styles.listItemDate}>{item.post.time}</span>
                        </div>
                    </div>
                ))}
            </div>
            <button
                className={classNames(styles.commentButton, styles.commentButtonClose)}
                style={{backgroundColor: colors.grey}}
                onClick={() => back()}
            ><Close color={colors.gold}/>
            </button>
            <div className={styles.commentInputWrapper}>
                <textarea
                    placeholder={'Напишите комментарий'}
                    value={comment?.post.text ?? ''}
                    onChange={event => writeComment(event.target.value)}
                    className={styles.commentInput}
                />
                <div className={styles.commentButtons}>
                    <button
                        className={styles.commentButton}
                        style={{backgroundColor: colors.greyL}}
                        onClick={handleSave}
                    ><span className={styles.commentButtonText}>отправить</span><Send color={colors.gold}/></button>
                </div>
            </div>
        </div>
    );
};
