import React from 'react';
import {IconProps} from './types';

export const Comments = ({color}: IconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1 3C1 2.44772 1.44772 2 2 2H18C18.5523 2 19 2.44772 19 3V10H22C22.5523 10 23 10.4477 23 11V19C23 19.5523 22.5523 20 22 20H19.9142L18.7071 21.2071C18.3166 21.5976 17.6834 21.5976 17.2929 21.2071L16.0858 20H11C10.4477 20 10 19.5523 10 19V16H8.91421L7.20711 17.7071C6.81658 18.0976 6.18342 18.0976 5.79289 17.7071L4.08579 16H2C1.44772 16 1 15.5523 1 15V3ZM17 14V4H3V14H4.5C4.76522 14 5.01957 14.1054 5.20711 14.2929L6.5 15.5858L7.79289 14.2929C7.98043 14.1054 8.23478 14 8.5 14H17ZM18 16H12V18H16.5C16.7652 18 17.0196 18.1054 17.2071 18.2929L18 19.0858L18.7929 18.2929C18.9804 18.1054 19.2348 18 19.5 18H21V12H19V15C19 15.5523 18.5523 16 18 16ZM5 7C5 6.44772 5.44772 6 6 6H12C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8H6C5.44772 8 5 7.55228 5 7ZM5 11C5 10.4477 5.44772 10 6 10H9C9.55228 10 10 10.4477 10 11C10 11.5523 9.55228 12 9 12H6C5.44772 12 5 11.5523 5 11Z"
                  fill={color} fillOpacity="0.85"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3 4H17V14H8.5C8.23478 14 7.98043 14.1054 7.79289 14.2929L6.5 15.5858L5.20711 14.2929C5.01957 14.1054 4.76522 14 4.5 14H3V4ZM5 7C5 6.44772 5.44772 6 6 6H12C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8H6C5.44772 8 5 7.55228 5 7ZM5 11C5 10.4477 5.44772 10 6 10H9C9.55228 10 10 10.4477 10 11C10 11.5523 9.55228 12 9 12H6C5.44772 12 5 11.5523 5 11Z"
                  fill={'black'}/>
        </svg>
    );
};
