import {useEffect, useState} from 'react';
import {Message} from '../components/comments/Comments';
import {socket} from '../App';
import {MessageToList} from '../components/listComments/ListComments';


interface ReturnMethods {
    sendMessage: (data: Message | MessageToList) => void;
    message: Comment | null;
}

interface SocketResponse {
    post: string;
}

export const useSocket = (): ReturnMethods => {

    const [message, setMessage] = useState<Comment | null>(null);

    useEffect(() => {
        socket.on('message', (data: SocketResponse) => {
            const post = JSON.parse(data.post) as Comment;
            setMessage(post);
        });

        return () => {
            socket.off('message');
        };
    }, []);

    const sendMessage = async (comment: Message | MessageToList) => {
        setMessage(null);
        socket.send(JSON.stringify({...comment}));
    };

    return {sendMessage, message};
};
