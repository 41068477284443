import React, {useEffect, useState} from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';
import {colors} from '../../utils/constants';
import {Close, Send} from '../../assets/icons';
import {useAppSelector} from '../../store/hooks';
import {useSocket} from '../../hooks';
import {Comment} from '../comments/Comments';
import dayjs from 'dayjs';
import {socket} from '../../App';
import {toast} from 'react-toastify';
import {useCollapse} from 'react-collapsed';
import {useDeletePostMutation, useGetCommentListMutation} from '../../pages/diary/store/diary.api';
import {CommentItem} from '../commentItem';

export interface MessageToList {
    post: Comment,
    commentsListId: string,
    accessIds: string[]
}

interface ListCommentsProps {
    _id: string;
    comments: string[],
    target: string[],
    participants: string[];
    isExpanded: boolean;
    onToggle: (action: 'open' | 'close') => void;
}

export const ListComments = ({_id: listId, target, isExpanded, onToggle}: ListCommentsProps) => {
    const {dataDiary} = useAppSelector(state => state.diary);
    const {sendMessage, message} = useSocket();
    const [getComments, {isLoading}] = useGetCommentListMutation();
    const [deletePost] = useDeletePostMutation();

    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

    const [list, setList] = useState<Comment[]>([]);
    const [comment, setComment] = useState<MessageToList | null>(null);
    const [activeCommentId, setActiveCommentId] = useState<string | null>(null);

    useEffect(() => {
        if (isExpanded) {
            getComments({id: listId})
                .unwrap()
                .then((data) => {
                    setList(data);
                });
        }
    }, [isExpanded]);

    useEffect(() => {
        if (message) {
            setList([...list, {...message} as unknown as Comment]);
        }
    }, [message]);

    const writeComment = (text: string) => {
        const item: Comment = {
            text,
            authorId: dataDiary.serviceId,
            authorName: dataDiary.authorName,
            date_create: dayjs().format('YYYY-MM-DD'),
            time: dayjs().format('HH:mm'),
            type: 'comment'
        };
        setComment({post: item, commentsListId: listId, accessIds: dataDiary.accessIds});
    };

    const handleSave = () => {
        if (!socket.connected) {
            return toast.error('Проверьте интернет соединение, или обратитесь в поддержку');
        }
        sendMessage(comment as MessageToList);
        setComment(null);
    };


    const deleteComment = (_id: string) => {
        deletePost({_id})
            .unwrap()
            .then(() => {
                if (list.length > 1) {
                    getComments({id: listId})
                        .unwrap()
                        .then((data) => {
                            setList(data);
                        });
                }
            });
    };

    const handleContextMenu = (id: string) => {
        setActiveCommentId(id);
    };

    const handleClickOutside = () => {
        setActiveCommentId(null);
    };

    return (
        <div className={styles.commentsWrapper}>
            <button {...getToggleProps({onClick: () => onToggle('open')})} className={styles.collapseBtn}>
                {!isExpanded ? <span>Комментарии
                    {target.includes(dataDiary.serviceId) ? <span className={styles.collapseBtnPing}/> : null}
                </span> : null}
            </button>
            <section {...getCollapseProps()}>
                <div className={styles.list}>
                    {isLoading
                        ? <div>загрузка...</div>
                        : list?.map((item, index) => {
                            const isAuthor = item.authorId === dataDiary.serviceId;
                            return (
                                <CommentItem
                                    key={item._id}
                                    item={item}
                                    isAuthor={isAuthor}
                                    deleteComment={deleteComment}
                                    activeCommentId={activeCommentId}
                                    onContextMenu={handleContextMenu}
                                    onClickOutside={handleClickOutside}
                                />
                            );
                        })}
                </div>
                <button
                    className={classNames(styles.commentButton, styles.commentButtonClose)}
                    style={{backgroundColor: colors.grey}}
                    onClick={() => onToggle('close')}>
                    <Close color={colors.gold}/>
                </button>
                <div className={styles.commentInputWrapper}>
                <textarea
                    placeholder={'Напишите комментарий'}
                    value={comment?.post.text ?? ''}
                    onChange={event => writeComment(event.target.value)}
                    className={styles.commentInput}
                />
                    <div className={styles.commentButtons}>
                        <button
                            className={styles.commentButton}
                            style={{backgroundColor: colors.greyL}}
                            type={'button'}
                            onClick={handleSave}
                        ><span className={styles.commentButtonText}>отправить</span><Send color={colors.gold}/></button>
                    </div>
                </div>
            </section>
        </div>
    );
};
