import {createSlice} from '@reduxjs/toolkit';
import {DairyState} from './types';
import {Comment} from '../../../components/comments/Comments';
import {diaryApi} from './diary.api';
import {format} from 'date-fns';

export const currentDate = format(new Date(), 'yyyy-MM-dd');

const initialState: DairyState = {
    firstHtmlText: null,
    intermediateText: null,
    list: [],
    currentDate: currentDate,
    dataDiary: {
        diaryId: '',
        accessIds: [],
        serviceId: '',
        studentId: '',
        authorName: '',
        postContentId: '',
        role: 'STUDENT'
    }
};

const diarySlice = createSlice({
    name: 'diary',
    initialState,
    reducers: {
        setIntermediateText: (state, {payload}: { payload: Comment | null }) => {
            state.intermediateText = payload;
        },
        setFirstHtmlText: (state, {payload}: { payload: Comment | null }) => {
            state.firstHtmlText = payload;
        },
        setDate: (state, {payload}: { payload: string }) => {
            state.currentDate = payload;
        },
        setStudentId: (state, {payload}) => {
            state.dataDiary.studentId = payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            diaryApi.endpoints.getDiary.matchFulfilled,
            (state, {payload}) => {
                state.dataDiary.diaryId = payload._id;
                state.dataDiary.serviceId = payload.serviceId;
                state.dataDiary.authorName = payload.authorName;
                state.dataDiary.accessIds = payload.accessIds;
                state.dataDiary.postContentId = payload.postContentId;
                state.dataDiary.role = payload.role ?? 'STUDENT';
            }
        );
    }
});

export const {setIntermediateText, setFirstHtmlText, setDate, setStudentId} = diarySlice.actions;
export default diarySlice.reducer;
