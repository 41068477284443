import {createSlice} from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'app',
    initialState: {
        token: ''
    },
    reducers: {
        setToken: (state, {payload}) => {
            state.token = payload;
        }
    }
});

export const {setToken} = appSlice.actions;
export default appSlice.reducer;

