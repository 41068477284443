import {appApi} from '../../../store/app.api';
import {DiaryResponse, PostToDiary} from './types';
import {Comment} from '../../../components/comments/Comments';

export const diaryApi = appApi.injectEndpoints({
    endpoints: build => ({
        setPost: build.mutation<{ postDiaryId: string }, { body: PostToDiary }>({
            query: ({body}) => ({
                url: '/create-post',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Diary', 'RecordDates'],
            transformResponse: ({postDiaryId}) => {
                return {postDiaryId};
            }
        }),
        updaterPost: build.mutation<{ postDiaryId: string }, { post: Comment }>({
            query: ({post}) => ({
                url: '/update-post',
                method: 'POST',
                body: {post}
            }),
            invalidatesTags: ['Diary', 'RecordDates'],
            transformResponse: ({postDiaryId}) => {
                return {postDiaryId};
            }
        }),
        deletePost: build.mutation<void, { _id: string }>({
            query: ({_id}) => ({
                url: '/delete-post',
                method: 'DELETE',
                body: {_id}
            }),
            invalidatesTags: ['Diary', 'RecordDates'],
        }),
        getDiary: build.query<DiaryResponse, { date_create: string; authorId: string }>({
            query: ({date_create, authorId}) => ({
                url: `/get-diary?date_create=${date_create}&authorId=${authorId}`,
                method: 'GET'
            }),
            providesTags: ['Diary'],
            transformResponse: (data: any) => {
                return {
                    _id: data._id,
                    items: data.items,
                    serviceId: data.serviceId,
                    authorName: data.authorName,
                    accessIds: data.accessIds,
                    postContentId: data.postContentId,
                    role: data.role ?? 'STUDENT'
                };
            }
        }),
        getCommentList: build.mutation<Comment[], { id: string }>({
            query: ({id}) => ({
                url: '/get-comment-list',
                method: 'POST',
                body: {id},
            }),
            invalidatesTags: ['Diary', 'RecordDates'],
            transformResponse: ({comments}: { comments: Comment[] }) => {
                return comments;
            }
        }),
        getRecordDates: build.query<{ dates: string[], unread: { date_create: string }[] }, { authorId: string }>({
            query: ({authorId}) => ({
                url: `/get-record-days?authorId=${authorId}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            providesTags: ['RecordDates'],
            transformResponse: ({
                                    dates,
                                    unread
                                }: { dates: string[], unread: { date_create: string }[] }) => {
                return {dates, unread};
            }
        })
    }), overrideExisting: true
});

export const {
    useSetPostMutation,
    useGetDiaryQuery,
    useGetRecordDatesQuery,
    useLazyGetRecordDatesQuery,
    useUpdaterPostMutation,
    useGetCommentListMutation,
    useDeletePostMutation
} = diaryApi;
