import React, {useState} from 'react';
import 'quill/dist/quill.snow.css';
import styles from './style.module.scss';
import {colors} from '../../utils/constants'; // Подключение стилей для тулбара
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

interface CustomToolbarProps {
    onSave: () => void;
    addEmoji: (emoji: any) => void;
}

export const CustomToolbar = ({onSave, addEmoji}: CustomToolbarProps) => {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    return (
        <div id="toolbar" className={styles.toolbar}>
            {/* Header dropdown */}
            <select className="ql-header">
                <option value="1">Заголовок 1</option>
                <option value="2">Заголовок 2</option>
                <option value="">Нормальный</option>
            </select>

            {/* Size dropdown */}
            {/*<select className="ql-size">*/}
            {/*    <option value="small"/>*/}
            {/*    <option selected/>*/}
            {/*    <option value="large"/>*/}
            {/*    <option value="huge"/>*/}
            {/*</select>*/}

            {/* Bold, Italic, Underline, Strike */}
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>

            {/* List: Ordered & Unordered */}
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>

            {/* Link */}
            <button className="ql-link"></button>

            {/* Align */}
            <select className="ql-align">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
            </select>

            {/* Color and Background */}
            <select className="ql-color">
                <option></option>
                <option value="#e60000"></option>
                <option value="#ff9900"></option>
                <option value="#ffff00"></option>
                <option value="#008a00"></option>
                <option value="#0066cc"></option>
                <option value="#9933ff"></option>
                <option value="#ffffff"></option>
                <option value="#facccc"></option>
                <option value="#ffebcc"></option>
                <option value="#ffffcc"></option>
                <option value="#cce8cc"></option>
                <option value="#cce0f5"></option>
                <option value="#ebd6ff"></option>
            </select>
            <select className="ql-background">
                <option></option>
                <option value="#e60000"></option>
                <option value="#ff9900"></option>
                <option value="#ffff00"></option>
                <option value="#008a00"></option>
                <option value="#0066cc"></option>
                <option value="#9933ff"></option>
                <option value="#ffffff"></option>
                <option value="#facccc"></option>
                <option value="#ffebcc"></option>
                <option value="#ffffcc"></option>
                <option value="#cce8cc"></option>
                <option value="#cce0f5"></option>
                <option value="#ebd6ff"></option>
            </select>
            <div className={styles.emoji}>
                {showEmojiPicker ?
                    <>
                        <div className={styles.emojiPicker}>
                            <Picker data={data} onEmojiSelect={addEmoji}/>
                        </div>
                        <div className={styles.emojiOverlay} onClick={() => setShowEmojiPicker(false)}/>
                    </> : null}
                <span
                    className={styles.emojiButton}
                    style={{borderColor: colors.gold}}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}>🙂
                </span>
            </div>
            <button
                className={styles.toolbarSave}
                style={{borderColor: colors.gold}}
                onClick={onSave}>Сохранить
            </button>
        </div>
    );
};


