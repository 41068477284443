import {Action, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import diary from '../pages/diary/store/diary.slice';
import {appApi} from './app.api';
import app from './app.slice';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';

const persistConfig = {
    key: 'chat',
    storage
};

const rootReducer = combineReducers({
    app,
    diary,
    [appApi.reducerPath]: appApi.reducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }).concat(appApi.middleware as any),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
