import React, {useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {useGetDiaryQuery, useSetPostMutation, useUpdaterPostMutation} from './store/diary.api';
import {setIntermediateText} from './store/diary.slice';
import {Editor} from '../../components/editor';
import {ListDiary} from '../../components/listDiary';
import {Record} from './store/types';
import {Comment} from '../../components/comments/Comments';
import styles from './style.module.scss';


export const Diary = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const [setPost] = useSetPostMutation();
    const [updatePost] = useUpdaterPostMutation();

    const {currentDate, dataDiary} = useAppSelector(state => state.diary);

    const {data, isLoading, isFetching, refetch} = useGetDiaryQuery({
        date_create: currentDate,
        authorId: dataDiary.studentId
    }, {refetchOnMountOrArgChange: true});
    const {intermediateText} = useAppSelector(state => state.diary);

    const [isEdit, setEdit] = useState<boolean>(false);
    const [editId, setEditId] = useState<string | undefined>(undefined);


    const handleSave = () => {
        const position = data?.items?.map(i => i.position).pop() ?? 1;
        if (intermediateText) {
            if (!editId) {
                setPost({body: {post: intermediateText, position: position, authorId: dataDiary.studentId}})
                    .unwrap()
                    .then(() => {
                        refetch();
                        dispatch(setIntermediateText(null));
                    }).catch(() => {
                });
            } else {
                updatePost({post: intermediateText as Comment})
                    .unwrap()
                    .then(() => {
                        refetch();
                        dispatch(setIntermediateText(null));
                    }).catch(() => {
                });
            }
        }

        setEdit(false);
    };

    return (
        <>
            {isFetching
                ? <div className={styles.loader}><p>...loading</p></div> : isEdit
                    ? <div ref={containerRef} className={styles.container}>
                        <Editor handleSave={handleSave} onCansel={() => setEdit(false)}/>
                    </div>
                    : <ListDiary
                        list={data?.items as Record[]}
                        handleRefetch={refetch}
                        handleCreate={(_id) => {
                            setEditId(_id);
                            setEdit(true);
                        }}/>
            }
        </>
    );
};
