import React, {useRef} from 'react';
import styles from './style.module.scss';
import {Button} from '../../fields';
import {Comments, Pen} from '../../assets/icons';
import {colors} from '../../utils/constants';
import {useClickOutside} from '../../hooks';

interface ControlProps {
    onEdit: () => void;
    onComment: () => void;
    onClickOutside: () => void;
}

export const Control = ({onEdit, onComment, onClickOutside}: ControlProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useClickOutside({
        ref: containerRef, onClickOutside
    });
    return (
        <div ref={containerRef} className={styles.control}>
            <Button
                className={styles.controlButton}
                style={{borderColor: colors.gold}}
                onClick={onComment}>
                <Comments
                    color={colors.gold}/>
            </Button>
            <Button
                className={styles.controlButton}
                style={{borderColor: colors.gold}}
                onClick={onEdit}>
                <Pen
                    color={colors.gold}/>
            </Button>
        </div>
    );
};
