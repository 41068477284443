import React, {HTMLAttributes, ReactNode} from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
}

export const Button = ({children, ...props}: ButtonProps) => {
    return (
        <button
            {...props}
            className={props.className ? classNames(styles.button, props.className) : styles.button}
        >
            {children}
        </button>
    );
};
