export const splitText = (event: any): any => {
    // const textElement = event.target;
    // const textContent = textElement.innerHTML;
    //
    // console.log('@@@ ', textContent);
    // // Получаем координаты клика
    // const x = event.clientX;
    // const y = event.clientY;
    //
    // // Создаем объект Range и Selection
    // const range = document.createRange();
    // const selection = window.getSelection();
    //
    // // Устанавливаем начальную позицию range
    // range.selectNodeContents(textElement);
    // selection?.removeAllRanges();
    // selection?.addRange(range);
    //
    // let clickOffset = 0;
    // let found = false;
    //
    // for (let i = 0; i < textContent.length; i++) {
    //     range.setStart(textElement.firstChild, i);
    //     range.setEnd(textElement.firstChild, i + 1);
    //     const rect = range.getBoundingClientRect();
    //
    //     if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
    //         clickOffset = i;
    //         found = true;
    //         break;
    //     }
    // }
    //
    // // Если не нашли точное попадание, проверяем ближайшие позиции
    // if (!found) {
    //     for (let i = 0; i < textContent.length; i++) {
    //         range.setStart(textElement.firstChild, i);
    //         range.setEnd(textElement.firstChild, i + 1);
    //         const rect = range.getBoundingClientRect();
    //
    //         if (rect.top <= y && rect.bottom >= y) {
    //             if (Math.abs(rect.left - x) < Math.abs(range.getBoundingClientRect().left - x)) {
    //                 clickOffset = i;
    //             }
    //         }
    //     }
    // }
    //
    // // Разделение текста на две части
    // const firstPart = textContent.slice(0, clickOffset);
    // const secondPart = textContent.slice(clickOffset);
    //
    // console.log('firstPart', firstPart);
    // console.log('secondPart', secondPart);
    // return {firstPart, secondPart};

    // const textElement = event.target;
    //
    // // Получаем координаты клика
    // const x = event.clientX;
    // const y = event.clientY;
    //
    // // Создаем объект Range и Selection
    // const range = document.createRange();
    // const selection = window.getSelection();
    //
    // // Устанавливаем начальную позицию range
    // range.selectNodeContents(textElement);
    // selection?.removeAllRanges();
    // selection?.addRange(range);
    //
    // // Функция для поиска узла и оффсета клика
    // const getClickOffset = (node: Node): { node: Node, offset: number } | null => {
    //     let foundNode = null;
    //     let foundOffset = 0;
    //
    //     for (let i = 0; i < node.childNodes.length; i++) {
    //         const child = node.childNodes[i];
    //
    //         if (child.nodeType === Node.TEXT_NODE) {
    //             range.selectNodeContents(child);
    //             const rect = range.getBoundingClientRect();
    //
    //             if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
    //                 for (let j = 0; j < child.textContent!.length; j++) {
    //                     range.setStart(child, j);
    //                     range.setEnd(child, j + 1);
    //                     const charRect = range.getBoundingClientRect();
    //
    //                     if (charRect.left <= x && charRect.right >= x && charRect.top <= y && charRect.bottom >= y) {
    //                         foundNode = child;
    //                         foundOffset = j;
    //                         break;
    //                     }
    //                 }
    //             }
    //         } else if (child.nodeType === Node.ELEMENT_NODE) {
    //             const rect = (child as Element).getBoundingClientRect();
    //             if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
    //                 foundNode = getClickOffset(child);
    //             }
    //         }
    //
    //         if (foundNode) break;
    //     }
    //
    //     // @ts-ignore
    //     return foundNode ? {node: foundNode, offset: foundOffset} : null;
    // };
    //
    // const clickOffset = getClickOffset(textElement);
    //
    // if (clickOffset) {
    //     // Создаем новый Range для выделения частей
    //     const firstPartRange = document.createRange();
    //     firstPartRange.setStart(textElement, 0);
    //     firstPartRange.setEnd(clickOffset.node, clickOffset.offset);
    //
    //     const secondPartRange = document.createRange();
    //     secondPartRange.setStart(clickOffset.node, clickOffset.offset);
    //     secondPartRange.setEnd(textElement, textElement.childNodes.length);
    //
    //     const firstPart = firstPartRange.cloneContents();
    //     const secondPart = secondPartRange.cloneContents();
    //
    //     console.log('firstPart', firstPart);
    //     console.log('secondPart', secondPart);
    //
    //     // Для примера, заменяем содержимое на две части (можно убрать эти строки)
    //     textElement.innerHTML = '';
    //     textElement.appendChild(firstPart);
    //     textElement.appendChild(document.createElement('br'));
    //     textElement.appendChild(secondPart);
    //
    //     return {firstPart, secondPart};
    // }
    //
    // return;

    // const textElement = event.target;
    //
    // // Получаем координаты клика
    // const x = event.clientX;
    // const y = event.clientY;
    //
    // // Создаем объект Range и Selection
    // const range = document.createRange();
    // const selection = window.getSelection();
    //
    // // Устанавливаем начальную позицию range
    // range.selectNodeContents(textElement);
    // selection?.removeAllRanges();
    // selection?.addRange(range);
    //
    // // Функция для поиска узла и оффсета клика
    // const getClickOffset = (node: Node): { node: Node, offset: number } | null => {
    //     let foundNode = null;
    //     let foundOffset = 0;
    //
    //     for (let i = 0; i < node.childNodes.length; i++) {
    //         const child = node.childNodes[i];
    //
    //         if (child.nodeType === Node.TEXT_NODE) {
    //             range.selectNodeContents(child);
    //             const rect = range.getBoundingClientRect();
    //
    //             if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
    //                 for (let j = 0; j < child.textContent!.length; j++) {
    //                     range.setStart(child, j);
    //                     range.setEnd(child, j + 1);
    //                     const charRect = range.getBoundingClientRect();
    //
    //                     if (charRect.left <= x && charRect.right >= x && charRect.top <= y && charRect.bottom >= y) {
    //                         foundNode = child;
    //                         foundOffset = j;
    //                         break;
    //                     }
    //                 }
    //             }
    //         } else if (child.nodeType === Node.ELEMENT_NODE) {
    //             const element = child as Element;
    //             const rect = element.getBoundingClientRect();
    //             if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
    //                 const result = getClickOffset(child);
    //                 if (result) {
    //                     foundNode = result.node;
    //                     foundOffset = result.offset;
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    //
    //     // Если не нашли точное попадание, проверяем между элементами
    //     if (!foundNode) {
    //         for (let i = 0; i < node.childNodes.length - 1; i++) {
    //             const startChild = node.childNodes[i];
    //             const endChild = node.childNodes[i + 1];
    //
    //             if (startChild.nodeType === Node.ELEMENT_NODE && endChild.nodeType === Node.ELEMENT_NODE) {
    //                 const startRect = (startChild as Element).getBoundingClientRect();
    //                 const endRect = (endChild as Element).getBoundingClientRect();
    //
    //                 if (startRect.bottom <= y && endRect.top >= y) {
    //                     foundNode = endChild;
    //                     foundOffset = 0;
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    //
    //     return foundNode ? {node: foundNode, offset: foundOffset} : null;
    // };
    //
    // const clickOffset = getClickOffset(textElement);
    //
    // if (clickOffset) {
    //     // Создаем новый Range для выделения частей
    //     const firstPartRange = document.createRange();
    //     firstPartRange.setStart(textElement, 0);
    //     firstPartRange.setEnd(clickOffset.node, clickOffset.offset);
    //
    //     const secondPartRange = document.createRange();
    //     secondPartRange.setStart(clickOffset.node, clickOffset.offset);
    //     secondPartRange.setEnd(textElement, textElement.childNodes.length);
    //
    //     const firstPartFragment = firstPartRange.cloneContents();
    //     const secondPartFragment = secondPartRange.cloneContents();
    //
    //     // Преобразуем DocumentFragment в HTML строку
    //     const fragmentToHtml = (fragment: DocumentFragment): string => {
    //         const tempDiv = document.createElement('div');
    //         tempDiv.appendChild(fragment);
    //         return tempDiv.innerHTML;
    //     };
    //
    //     const firstPartHtml = fragmentToHtml(firstPartFragment);
    //     const secondPartHtml = fragmentToHtml(secondPartFragment);
    //
    //     console.log('firstPartHtml', firstPartHtml);
    //     console.log('secondPartHtml', secondPartHtml);
    //
    //     // Для примера, заменяем содержимое на две части (можно убрать эти строки)
    //     textElement.innerHTML = '';
    //     textElement.innerHTML = firstPartHtml + '<br>' + secondPartHtml;
    // }
    //
    // return;

    // const textElement = event.target;
    //
    // // Получаем координаты клика
    // const x = event.clientX;
    // const y = event.clientY;
    //
    // // Создаем объект Range и Selection
    // const range = document.createRange();
    // const selection = window.getSelection();
    //
    // // Устанавливаем начальную позицию range
    // range.selectNodeContents(textElement);
    // selection?.removeAllRanges();
    // selection?.addRange(range);
    //
    // // Функция для поиска узла и оффсета клика
    // const getClickOffset = (node: Node): { node: Node, offset: number } | null => {
    //     let foundNode: Node | null = null;
    //     let foundOffset = 0;
    //
    //     const traverseChildren = (currentNode: Node, offset: number): void => {
    //         if (currentNode.nodeType === Node.TEXT_NODE) {
    //             range.selectNodeContents(currentNode);
    //             const rect = range.getBoundingClientRect();
    //
    //             if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
    //                 for (let j = 0; j < (currentNode as Text).textContent!.length; j++) {
    //                     range.setStart(currentNode, j);
    //                     range.setEnd(currentNode, j + 1);
    //                     const charRect = range.getBoundingClientRect();
    //
    //                     if (charRect.left <= x && charRect.right >= x && charRect.top <= y && charRect.bottom >= y) {
    //                         foundNode = currentNode;
    //                         foundOffset = j;
    //                         break;
    //                     }
    //                 }
    //             }
    //         } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
    //             const element = currentNode as Element;
    //             const rect = element.getBoundingClientRect();
    //
    //             if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
    //                 for (let i = 0; i < element.childNodes.length; i++) {
    //                     traverseChildren(element.childNodes[i], 0);
    //                 }
    //             }
    //         }
    //     };
    //
    //     traverseChildren(node, 0);
    //
    //     // Если не нашли точное попадание, проверяем между элементами
    //     if (!foundNode) {
    //         for (let i = 0; i < node.childNodes.length - 1; i++) {
    //             const startChild = node.childNodes[i];
    //             const endChild = node.childNodes[i + 1];
    //
    //             if (startChild.nodeType === Node.ELEMENT_NODE && endChild.nodeType === Node.ELEMENT_NODE) {
    //                 const startRect = (startChild as Element).getBoundingClientRect();
    //                 const endRect = (endChild as Element).getBoundingClientRect();
    //
    //                 if (startRect.bottom <= y && endRect.top >= y) {
    //                     foundNode = endChild;
    //                     foundOffset = 0;
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    //
    //     return foundNode ? {node: foundNode, offset: foundOffset} : null;
    // };
    //
    // const clickOffset = getClickOffset(textElement);
    //
    // if (clickOffset) {
    //     // Создаем новый Range для выделения частей
    //     const firstPartRange = document.createRange();
    //     firstPartRange.setStart(textElement, 0);
    //     firstPartRange.setEnd(clickOffset.node, clickOffset.offset);
    //
    //     const secondPartRange = document.createRange();
    //     secondPartRange.setStart(clickOffset.node, clickOffset.offset);
    //     secondPartRange.setEnd(textElement, textElement.childNodes.length);
    //
    //     const firstPartFragment = firstPartRange.cloneContents();
    //     const secondPartFragment = secondPartRange.cloneContents();
    //
    //     // Преобразуем DocumentFragment в HTML строку
    //     const fragmentToHtml = (fragment: DocumentFragment): string => {
    //         const tempDiv = document.createElement('div');
    //         tempDiv.appendChild(fragment);
    //         return tempDiv.innerHTML;
    //     };
    //
    //     const firstPartHtml = fragmentToHtml(firstPartFragment);
    //     const secondPartHtml = fragmentToHtml(secondPartFragment);
    //
    //     console.log('firstPartHtml', firstPartHtml);
    //     console.log('secondPartHtml', secondPartHtml);
    //
    //     // Для примера, заменяем содержимое на две части (можно убрать эти строки)
    //     textElement.innerHTML = '';
    //     textElement.innerHTML = firstPartHtml + '<br>' + secondPartHtml;
    // }
    //
    // return;
};

export const splitHtml = (event: any): { firstPartHtml: string; secondPartHtml: string } | null => {
    const textElement = event.target;

    // Проверяем, что клик был внутри .container-html
    const container = textElement.closest('.container-html');
    if (!container) return null;

    // Получаем координаты клика
    const x = event.clientX;
    const y = event.clientY;

    // Создаем объект Range и Selection
    const range = document.createRange();
    const selection = window.getSelection();

    // Устанавливаем начальную позицию range
    range.selectNodeContents(textElement);
    selection?.removeAllRanges();
    selection?.addRange(range);

    // Функция для поиска узла и оффсета клика
    const getClickOffset = (node: Node): { node: Node, offset: number } | null => {
        let foundNode: Node | null = null;
        let foundOffset = 0;

        const traverseChildren = (currentNode: Node, offset: number): void => {
            if (currentNode.nodeType === Node.TEXT_NODE) {
                range.selectNodeContents(currentNode);
                const rect = range.getBoundingClientRect();

                if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
                    for (let j = 0; j < (currentNode as Text).textContent!.length; j++) {
                        range.setStart(currentNode, j);
                        range.setEnd(currentNode, j + 1);
                        const charRect = range.getBoundingClientRect();

                        if (charRect.left <= x && charRect.right >= x && charRect.top <= y && charRect.bottom >= y) {
                            foundNode = currentNode;
                            foundOffset = j;
                            break;
                        }
                    }
                }
            } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
                const element = currentNode as Element;
                const rect = element.getBoundingClientRect();

                if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
                    for (let i = 0; i < element.childNodes.length; i++) {
                        traverseChildren(element.childNodes[i], 0);
                    }
                }
            }
        };

        traverseChildren(node, 0);

        // Если не нашли точное попадание, проверяем между элементами
        if (!foundNode) {
            for (let i = 0; i < node.childNodes.length - 1; i++) {
                const startChild = node.childNodes[i];
                const endChild = node.childNodes[i + 1];

                if (startChild.nodeType === Node.ELEMENT_NODE && endChild.nodeType === Node.ELEMENT_NODE) {
                    const startRect = (startChild as Element).getBoundingClientRect();
                    const endRect = (endChild as Element).getBoundingClientRect();

                    if (startRect.bottom <= y && endRect.top >= y) {
                        foundNode = endChild;
                        foundOffset = 0;
                        break;
                    }
                }
            }
        }

        return foundNode ? {node: foundNode, offset: foundOffset} : null;
    };

    const clickOffset = getClickOffset(textElement);

    if (clickOffset) {
        // Создаем новый Range для выделения частей
        const firstPartRange = document.createRange();
        firstPartRange.setStart(textElement, 0);
        firstPartRange.setEnd(clickOffset.node, clickOffset.offset);

        const secondPartRange = document.createRange();
        secondPartRange.setStart(clickOffset.node, clickOffset.offset);
        secondPartRange.setEnd(textElement, textElement.childNodes.length);

        const firstPartFragment = firstPartRange.cloneContents();
        const secondPartFragment = secondPartRange.cloneContents();

        // Преобразуем DocumentFragment в HTML строку
        const fragmentToHtml = (fragment: DocumentFragment): any => {
            const tempDiv = document.createElement('p');
            tempDiv.appendChild(fragment);
            return tempDiv; //.innerHTML;
        };

        const firstPartHtml = fragmentToHtml(firstPartFragment);
        const secondPartHtml = fragmentToHtml(secondPartFragment);
        let emptyDiv = document.createElement('div');
        emptyDiv.className = 'separator';

        let chatContainer = document.createElement('div');
        emptyDiv.id = 'new-chat';


        // Для примера, заменяем содержимое на две части (можно убрать эти строки)
        textElement.innerHTML = '';
        textElement.append(firstPartHtml);
        textElement.appendChild(emptyDiv);
        textElement.appendChild(chatContainer);
        textElement.append(secondPartHtml);
        //const newHtml = document.querySelector('.container-html') as Element;

        const data: string[] = container.innerHTML.split('<div class="separator" id="new-chat"></div>');

        return {firstPartHtml: data[0], secondPartHtml: data[1]};
    }

    return null;
};
