import React, {useEffect, useState} from 'react';
import './App.css';
import {Diary} from './pages/diary';
import {io} from 'socket.io-client';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAppDispatch} from './store/hooks';
import {setToken} from './store/app.slice';
import {BASE_URL} from './store/app.api';
import {setStudentId} from './pages/diary/store/diary.slice';

export const socket = io(BASE_URL, {
    autoConnect: false,
    auth: {
        token: ''
    },
    reconnection: true, // Включить переподключение
    reconnectionAttempts: 10, // Количество попыток переподключения
    reconnectionDelay: 1000, // Задержка перед первой попыткой переподключения (в миллисекундах)
    reconnectionDelayMax: 5000, // Максимальная задержка между попытками переподключения
    timeout: 20000, // Время ожидания ответа сервера (в миллисекундах)
    transports: ['polling', 'websocket'],
});

function App() {

    const dispatch = useAppDispatch();

    const [isConnect, setConnect] = useState<boolean>(socket.connected);

    useEffect(() => {
        const handleMessage = (event: any) => {

            if (event.data.token) {
                if (event.data.token) {

                    dispatch(setToken(event.data.token));
                    socket.auth = {token: event.data.token};
                    socket.connect();

                    socket.on('connect', () => {
                        toast.info('Соединение установлено.');
                        setTimeout(() => {
                            setConnect(true);
                        }, 500);
                    });

                    socket.on('connect_error', (e) => {
                        console.log('ERR', e);
                        toast.error('Ошибка соединения.');
                    });
                }
            }
            if (event.data.authorId) {
                dispatch(setStudentId(event.data.authorId));
            }
        };

        // // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlSWQiOiIxIiwicm9sZSI6Ik1BTkFHRVIiLCJpYXQiOjE3MjUyMjIxNjQsImV4cCI6MTcyNTMwNDk2NH0.fKlbMYE3t9hkQfBBcOvnsSgcbeK2JFcs6gacNrdMyBo'; //m
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlSWQiOiI0Iiwicm9sZSI6IlNUVURFTlQiLCJpYXQiOjE3MjYzMzIzMzYsImV4cCI6MTcyNjQxNTEzNn0.53QWqpzfCAmuq7gseJFXEujlY-bOmfolM7MoEv68tW0'; //s
        // //const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXJ2aWNlSWQiOiI0MiIsInJvbGUiOiJTVVBFUl9NQU5BR0VSIiwiaWF0IjoxNzI1Mzc3MTM2LCJleHAiOjE3MjU0NTk5MzZ9.6qgvcyFqS7TdgeiGqItYzNm9pAo7Sx5Uo661jakSz94'; //SM
        // dispatch(setToken(token));
        // socket.auth = {token};
        // socket.connect();
        //
        // socket.on('connect', () => {
        //     toast.info('Соединение установлено.');
        //     setTimeout(() => {
        //         setConnect(true);
        //     }, 500);
        // });
        //
        // socket.on('connect_error', (e) => {
        //     toast.error('Ошибка соединения.');
        // });
        //
        // dispatch(setStudentId('4'));


        /////////////////////////////// not comments
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div className="App">
            {isConnect ? <Diary/> : <p>Устанавливаем соединение</p>}
            <ToastContainer position={'bottom-left'} autoClose={5000}/>

        </div>
    );
}

export default App;
