import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError,} from '@reduxjs/toolkit/query/react';
import {getGlobalAbortController} from './abortController';
import {toast} from 'react-toastify';

const isProd = window.location.host !== 'localhost:3000';

export const BASE_URL: string = isProd ? 'https://api.ro-chat.ru' : 'http://localhost:8080';
export const BASE_URL_API: string = `${BASE_URL}/api/v1`;

interface ErrorData {
    message: string;
}

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL_API,
    credentials: 'same-origin',
    prepareHeaders: (headers, {getState}) => {
        const {token} = (getState() as any).app;

        if (token !== '') {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    fetchFn: async (input, init) => {
        try {
            return await fetch(input, {
                ...init,
                signal: getGlobalAbortController().signal,
            });
        } catch (error: any) {
            throw error;
        }
    },
});
//
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error) {
        toast.error((result.error.data as ErrorData)?.message ?? 'Ошибка', {
            position: 'top-center',
            autoClose: false
        });
    }
    return result;
};


export const appApi = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Diary', 'RecordDates'],
    refetchOnFocus: true,
    keepUnusedDataFor: 0,
    endpoints: build => ({})
});

