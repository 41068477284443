import React from 'react';
import {IconProps} from './types';

export const Pen = ({color}: IconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.5116 1.24406C17.9021 0.853541 18.5353 0.853541 18.9258 1.24406L23.1685 5.48669C23.356 5.67423 23.4613 5.92858 23.4613 6.1938C23.4613 6.45902 23.356 6.71337 23.1685 6.90091L18.9264 11.143L18.9252 11.1441L7.61211 22.4573C7.42456 22.6448 7.1702 22.7502 6.90498 22.7502L2.66246 22.7501C2.1102 22.75 1.6625 22.3023 1.66248 21.7501L1.66235 17.5075C1.66235 17.2423 1.7677 16.9879 1.95525 16.8004L13.2657 5.48997L13.2689 5.48669L13.2722 5.48343L17.5116 1.24406ZM13.9761 7.60802L3.66237 17.9217L3.66245 20.7501L6.49079 20.7501L16.8045 10.4365L13.9761 7.60802ZM18.2187 9.02224L15.3903 6.19381L18.2187 3.36538L21.0471 6.1938L18.2187 9.02224Z"
                  fill="black" fillOpacity="0.85"/>
            <path d="M3.66235 17.9216L13.976 7.60787L16.8045 10.4363L6.49078 20.75L3.66244 20.7499L3.66235 17.9216Z"
                  fill={color}/>
            <path d="M18.2187 9.02209L15.3903 6.19366L18.2187 3.36523L21.0471 6.19365L18.2187 9.02209Z" fill={color}/>
        </svg>
    );
};
