import React from 'react';
import {IconProps} from './types';

export const Notepad = ({color}: IconProps) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.5 2.5C12.2238 2.5 12 2.72385 12 3V3.97525C12 4.54172 11.5407 5 10.9753 5H10V6H15V5H14.0247C13.4593 5 13 4.54172 13 3.97525V3C13 2.72385 12.7762 2.5 12.5 2.5ZM17 5V6.5C17 7.32843 16.3284 8 15.5 8H9.5C8.67157 8 8 7.32843 8 6.5V5H6V21H19V5H17ZM10 3C10 1.6193 11.1193 0.5 12.5 0.5C13.8807 0.5 15 1.6193 15 3H19.5C20.3284 3 21 3.67159 21 4.5V21.5C21 22.3284 20.3284 23 19.5 23H5.5C4.67157 23 4 22.3284 4 21.5V4.5C4 3.67159 4.67155 3 5.5 3H10Z"
                  fill={color} fillOpacity="0.85"/>
            <path
                d="M12 3C12 2.72385 12.2238 2.5 12.5 2.5C12.7762 2.5 13 2.72385 13 3V3.97525C13 4.54172 13.4593 5 14.0247 5H15V6H10V5H10.9753C11.5407 5 12 4.54172 12 3.97525V3Z"
                fill={color}/>
        </svg>
    );
};
