import React from 'react';
import {IconProps} from './types';

export const Send = ({color}: IconProps) => {
    return (
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.6976 2.28355C21.9745 2.55309 22.0713 2.958 21.9462 3.3236L15.4488 22.3236C15.3055 22.7429 14.9033 23.0178 14.4605 22.9991C14.0178 22.9805 13.6402 22.6727 13.5326 22.2429L11.1847 12.865L1.81005 11.051C1.37001 10.9658 1.04037 10.5983 1.00343 10.1516C0.966481 9.70493 1.23125 9.28823 1.65132 9.13194L20.6513 2.06279C21.0134 1.92806 21.4208 2.014 21.6976 2.28355ZM13.1085 12.3141L14.6511 18.4755L19.371 4.67308L5.68755 9.76416L11.672 10.9222L14.1255 8.46867C14.516 8.07814 15.1492 8.07814 15.5397 8.46867C15.9302 8.85919 15.9302 9.49236 15.5397 9.88288L13.1085 12.3141Z"
                  fill="black" fillOpacity="0.85"/>
            <path
                d="M14.651 18.4752L13.1084 12.3139L15.5396 9.88265C15.9302 9.49213 15.9302 8.85896 15.5396 8.46844C15.1491 8.07792 14.5159 8.07792 14.1254 8.46844L11.6719 10.9219L5.6875 9.76394L19.371 4.67285L14.651 18.4752Z"
                fill={color}/>
        </svg>
    );
};
