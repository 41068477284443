import React, {useRef} from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';
import {Close, Pen} from '../../assets/icons';
import {colors} from '../../utils/constants';
import {Comment} from '../comments/Comments';
import {useClickOutside} from '../../hooks';

interface CommentItemProps {
    item: Comment;
    isAuthor: boolean;
    activeCommentId: string | null;
    deleteComment: (id: string) => void;
    onClickOutside: () => void;
    onContextMenu: (id: string) => void;
}

const reversData = (date: string): string => {
    return date.split('-').reverse().join('-');
};

export const CommentItem = (
    {
        isAuthor,
        item,
        activeCommentId,
        onClickOutside,
        deleteComment,
        onContextMenu
    }: CommentItemProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useClickOutside({ref: containerRef, onClickOutside});


    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        onClickOutside();
        onContextMenu(item._id as string);
    };

    const showMessageControl = activeCommentId === item._id;

    return (
        <div
            className={isAuthor
                ? classNames(styles.item, styles.itemAuthor)
                : styles.item}
            onContextMenu={handleContextMenu}
        >
            <div className={styles.itemBody}>
                <span className={styles.itemText}>{item.text}</span>
            </div>
            <div className={styles.itemFooter}>
                <p className={styles.itemName}>{item.authorName ?? ''}</p>
                <div className={styles.itemDateTime}>
                    <p className={styles.itemDate}>{reversData(item.date_create)}</p>
                    <p className={styles.itemDate}>{item.time}</p>
                </div>

            </div>
            {showMessageControl
                ? <div ref={containerRef}
                       className={styles.messageControl}>
                        <span
                            className={styles.messageControlItem}
                            onClick={() => {
                            }}>
                            <Pen color={colors.gold}/>
                        </span>
                    <span
                        className={styles.messageControlItem}
                        onClick={() => deleteComment(item._id as string)}>
                            <Close color={colors.gold}/>
                        </span>
                </div>
                : null}
        </div>
    );
};
