import React, {useEffect, useState} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import {ru} from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './style.module.scss';
import {format, getDate} from 'date-fns';
import {useLazyGetRecordDatesQuery} from '../../pages/diary/store/diary.api';
import classNames from 'classnames';
import {useAppSelector} from '../../store/hooks';
import {Button} from '../../fields';
import {colors} from '../../utils/constants';
import {Calendar} from '../../assets/icons';

registerLocale('ru', ru);

interface RecordsCalendarProps {
    onSelect: (date: string) => void;
}

export const RecordsCalendar = (
    {
        onSelect,
    }: RecordsCalendarProps
) => {
    const {studentId} = useAppSelector(state => state.diary.dataDiary);
    const [getCalendar, {data}] = useLazyGetRecordDatesQuery();
    const {currentDate} = useAppSelector(state => state.diary);

    const [showCalendar, setShowCalendar] = useState<boolean>(false);

    useEffect(() => {
        getCalendar({authorId: studentId});
    }, []);

    const renderDay = (day: any, date: Date) => {
        const formatDateStr = format(date as Date, 'yyyy-MM-dd');
        const isWriting = data?.dates.includes(formatDateStr);

        const unread = data?.unread.find(i => i.date_create === formatDateStr);

        return (
            <div
                className={isWriting
                    ? classNames(styles.calendarItem, styles.calendarItemActive)
                    : styles.calendarItem}
            >
                {getDate(date)}
                {unread !== undefined ? <div className={styles.calendarItemPink}/> : null}
            </div>
        );
    };

    const commonCount = data?.unread.length ?? 0;

    return (
        <>
            {showCalendar ? <div className={styles.wrapper}>
                <div className={styles.calendar}>
                    <DatePicker
                        inline
                        locale={'ru'}
                        dateFormat="P"
                        renderDayContents={renderDay}
                        selected={new Date(currentDate)}
                        onSelect={(date) => onSelect(format(date as Date, 'yyyy-MM-dd'))}
                    />
                </div>
                <div className={styles.overlay} onClick={() => setShowCalendar(false)}/>
            </div> : null}
            <Button
                className={styles.calendarBtn}
                style={{borderColor: colors.gold}}
                onClick={() => setShowCalendar(!showCalendar)}
            >
                <span className={styles.calendarBtnUnread}>
                    {commonCount > 0 ? `${commonCount}` : ''}
                </span>
                <Calendar color={colors.gold}/>
            </Button>
        </>
    );
};
