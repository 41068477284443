import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import styles from './style.module.scss';
import {Notepad} from '../../assets/icons';
import {colors} from '../../utils/constants';
import {Button} from '../../fields';
import {setDate, setFirstHtmlText, setIntermediateText} from '../../pages/diary/store/diary.slice';
import {splitHtml} from '../../helpers/splitText';
import {createPortal} from 'react-dom';
import {Comments} from '../comments';
import {Comment} from '../comments/Comments';
import {Record} from '../../pages/diary/store/types';
import {Control} from './Control';
import {ListComments} from '../listComments';
import {RecordsCalendar} from '../calendar';
import {useDeletePostMutation} from '../../pages/diary/store/diary.api';


const defPosition = {x: 0, y: 0, id: ''};

interface ListDiaryProps {
    handleCreate: (_id: string | undefined) => void;
    list: Record[];
    handleRefetch: () => void;
}

const Chat = ({position, handleRefetch}: { position: number, handleRefetch: () => void }) => {
    const [isVisible, setIsVisible] = useState(true);
    const container = document.getElementById('new-chat');

    // Проверяем, что контейнер существует
    if (!container) {
        return null;
    }
    return createPortal(
        <Comments
            position={position}
            onHide={() => {
                handleRefetch();
            }}
        />,
        container
    );
};

export const ListDiary = (
    {
        handleCreate, list, handleRefetch
    }: ListDiaryProps
) => {
    const dispatch = useAppDispatch();
    const {dataDiary, currentDate} = useAppSelector(state => state.diary);

    const [position, setPosition] = useState<{ x: number, y: number, id: string }>(defPosition);
    const [targetElement, setTargetElement] = useState<any>(null);
    const [postPosition, setPostPosition] = useState<number>(0);
    const [expandedSection, setExpandedSection] = useState<string | null>(null); // Состояние для отслеживания, какой блок открыт

    const handleToggle = (id: string) => {
        setExpandedSection(prev => (prev === id ? null : id)); // Открытие одного и закрытие других
    };

    const [deletePost] = useDeletePostMutation();

    const handleClick = (event: any, id: string | undefined) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPosition(defPosition);
        setTimeout(() => {
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            id && setPosition({x, y, id});
        }, 100);
        setTargetElement(event);
    };

    const handleEdit = (item: Comment) => {
        dispatch(setIntermediateText(item));
        handleCreate(item._id);
    };

    const handleAddComment = (item: Comment, position: number) => {
        setPosition(defPosition);

        const data = splitHtml(targetElement);
        if (data) {
            const {secondPartHtml, firstPartHtml} = data;
            setPostPosition(position);

            dispatch(setFirstHtmlText({
                _id: item._id,
                authorId: dataDiary.serviceId,
                type: 'text',
                text: firstPartHtml,
                date_create: item.date_create,
                time: item.time
            }));
            dispatch(setIntermediateText({
                authorId: dataDiary.serviceId,
                type: 'text',
                text: secondPartHtml,
                date_create: item.date_create,
                time: item.time
            } as Comment));
        }
    };

    const handleSelectDate = (date: string) => {
        dispatch(setDate(date));
    };


    const handleDelete = (_id: string) => {
        deletePost({_id});
    };

    return (
        <div className={styles.listWrapper}>
            <div className={styles.background}/>
            <div className={styles.listWrapperInner}>
                <div className={styles.post}>
                    <h1 className={styles.title}>{currentDate}</h1>
                    {list?.map((post: any, index) => {

                        return (
                            <div className={styles.postInner} key={index}>
                                {post.refModel === 'PostItem' ?
                                    <>
                                        <div
                                            key={post._id}
                                            className={'container-html'}
                                            dangerouslySetInnerHTML={{__html: (post.item as Comment)?.text}}
                                            onClick={(event) => handleClick(event, post._id)}
                                        />
                                        <div className={styles.postFooter}>
                                            <div className={styles.time}>
                                                <span className={styles.timeSubtext}>время написания:</span>
                                                <span className={styles.timeText}>{(post.item as Comment)?.time}</span>
                                            </div>
                                            <button
                                                className={styles.postInnerDelete}
                                                onClick={() => handleDelete((post.item as Comment)._id as string)}
                                            >удалить
                                            </button>
                                        </div>

                                        {position.x > 0 && position.id === post._id
                                            ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: position.y,
                                                        left: position.x,
                                                        transform: 'translate(-20%, 20%)'
                                                    }}
                                                >
                                                    <Control
                                                        onComment={() => {
                                                            handleAddComment(
                                                                (post.item as Comment),
                                                                post.position,
                                                            );
                                                        }}
                                                        onClickOutside={() => setPosition(defPosition)}
                                                        onEdit={() => handleEdit((post.item as Comment))}/>
                                                </div>
                                            )
                                            : null
                                        }
                                    </>
                                    : <div>
                                        <ListComments
                                            {...post?.item}
                                            isExpanded={expandedSection === post.item._id}
                                            onToggle={(action) => handleToggle(action ? post.item._id : null)}
                                        />
                                    </div>}
                            </div>
                        );
                    })}
                </div>
                <Chat position={postPosition} handleRefetch={handleRefetch}/>
                <div className={styles.toolbar}>

                    {dataDiary.role === 'MANAGER' || dataDiary.role === 'SUPER_MANAGER' ? null : (
                        <Button
                            className={styles.toolbarButton}
                            style={{borderColor: colors.gold}}
                            onClick={() => handleCreate(undefined)}
                        >
                            <Notepad color={colors.gold}/>
                        </Button>
                    )}
                </div>
                <RecordsCalendar
                    onSelect={handleSelectDate}
                />
            </div>


        </div>
    );
};


